import './src/styles/ignorePurgecss.css'
import './src/styles/styles.css'
import './src/styles/custom.css'
import './src/styles/mobile-styles.min.css'
import './src/styles/tablet-styles.min.css'
import './src/styles/desktop-lg-styles.min.css'
import './src/styles/desktop-xl-styles.min.css'

const addScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js')
  }
}
